
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  setup () {
    const router = useRouter()
    const back = () => {
      router.push({ path: '/policyList' })
    }
    return {
      back
    }
  }
})
