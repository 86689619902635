<template>
  <div class="matchResult base_min_width">
    <div class="resultTips flex_col">
      <img src="@/assets/images/preciseMatch/matchResult.png" alt="" />
      <span>您的精准匹配需求已提交！</span>
      <span>由于精准匹配较为复杂，匹配完成后3天内将会对您进行手机通知</span>
      <div class="matchBtn pointer" @click="back">返回</div>
    </div>
  </div>
</template>
<script lang='ts'>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  setup () {
    const router = useRouter()
    const back = () => {
      router.push({ path: '/policyList' })
    }
    return {
      back
    }
  }
})
</script>
<style lang='scss' scoped>
.matchResult {
  width: 100%;
  background: #f9f9f9;
  padding-top: 236px;
  padding-bottom: 136px;
  .resultTips {
    width: 546px;
    margin: auto;
    background: #ffffff;
    border-radius: 6px;
    align-items: center;
    padding-top: 54px;
    padding-bottom: 64px;
    img {
      width: 72px;
      height: 72px;
    }
    & > span:nth-of-type(1) {
      margin-top: 25px;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
    }
    & > span:nth-of-type(2) {
      margin-top: 13px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
    .matchBtn {
      margin-top: 25px;
      width: 102px;
      height: 48px;
      background: #f1f3f3;
      border: 1px solid #333333;
      border-radius: 24px;
      line-height: 48px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
}
</style>
